import { useContext } from 'react';
import { Checkbox } from '@mantine/core';
import { cloneDeep } from 'lodash';

import { CustomTableContext } from '../CustomTableContext';

export const CustomTableBody = ({ data, columns }) => {
  const { selectable, selections, setSelections } = useContext(CustomTableContext);

  const handleRowSelection = (rowIdx: number, checked: boolean) => {
    let selectionsCopy = cloneDeep(selections);
    if (checked) {
      // add at rowIdx
      selectionsCopy.push(rowIdx);
      selectionsCopy.sort();
    } else {
      // remove element at rowIdx
      selectionsCopy = selectionsCopy.filter((selectionIdx) => selectionIdx !== rowIdx);
    }

    setSelections(selectionsCopy);
  };

  return (
    <tbody>
      {data.map((row, rowIdx) => (
        <tr key={rowIdx}>
          {selectable && (
            <td>
              <Checkbox
                onChange={(event) => handleRowSelection(rowIdx, event.target.checked)}
                checked={selections.findIndex((selectionIdx) => selectionIdx === rowIdx) !== -1}
              />
            </td>
          )}
          {columns.map((column, columnIndex) => (
            <td key={columnIndex}>{column.cell(row)}</td>
          ))}
        </tr>
      ))}
    </tbody>
  );
};
