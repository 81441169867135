import { Pagination, Select, createStyles } from '@mantine/core';

import { usePagination } from '../hooks';

const useStyles = createStyles(() => ({
  pagination: {
    display: 'flex',
    justifyContent: 'flex-end',

    padding: '25px 50px',
    border: '0.0625rem solid #dee2e6',

    '& > * + *': {
      marginLeft: 20,
    },
  },
}));

export const CustomTablePagination = ({ total, onPaginationChange }) => {
  const { classes } = useStyles();

  const { onPageChange, onPageSizeChange, pagination } = usePagination({ onPaginationChange });

  return (
    <div className={classes.pagination}>
      <Select
        w={80}
        allowDeselect
        type="number"
        defaultValue={'10'}
        data={['10', '50', '100']}
        onChange={onPageSizeChange}
        value={pagination.size.toString()}
      />
      <Pagination total={Math.ceil(total / pagination.size)} onChange={onPageChange} value={pagination.page} />
    </div>
  );
};
