import { useMutation } from '@tanstack/react-query';

import { graphql } from '../../../api';
import { useClient } from '../useClient';

const createWallet = graphql(`
  mutation CreateWallet {
    createWallet
  }
`);

export const useCreateWallet = () => {
  const client = useClient();

  return useMutation({
    mutationFn: async () => client.request(createWallet),
  });
};
