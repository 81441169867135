import { createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider } from 'react-router-dom';

import { Auth, Activate, Help, Settings, VerifiablePresentations } from '../screens';
import { ProtectedRoute, DeactivateRoute } from '../components';
import { UserLayout, AuthLayout } from '../layout';

export const Navigation = () => {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route
          path="/"
          element={
            <ProtectedRoute redirectTo="/auth">
              <UserLayout />
            </ProtectedRoute>
          }
        >
          <Route path="/" element={<Navigate to="/verifiable-presentations" />} />
          <Route path="verifiable-presentations" element={<VerifiablePresentations />} />
          <Route path="settings" element={<Settings />} />
          <Route path="help" element={<Help />} />
        </Route>
        <Route
          path="/auth"
          element={
            <DeactivateRoute redirectTo="/">
              <AuthLayout />
            </DeactivateRoute>
          }
        >
          <Route path="" element={<Auth />} />
          <Route path="activate" element={<Activate />} />
        </Route>
      </Route>,
    ),
  );

  return <RouterProvider router={router} />;
};
