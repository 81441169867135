import { useQuery, useQueryClient } from '@tanstack/react-query';

import { graphql } from '../../../api';
import { useClient } from '../useClient';
import { TokenService } from '../../../services';

const retrieveMyUserQuery = graphql(`
  query RetrieveMyUser {
    retrieveMyUser {
      _id
      firstName
      lastName
      email
      address {
        street
        zipCode
        region
        country
      }
      hasActiveWallet
    }
  }
`);

export const useMyUser = () => {
  const client = useClient();
  return useQuery(
    ['myUser'],
    async () => {
      try {
        if (!TokenService.getToken()) {
          return { retrieveMyUser: null };
        }

        client.setHeaders({
          authorization: `Bearer ${TokenService.getToken()}`,
        });

        const res = await client.request(retrieveMyUserQuery);
        return res;
      } catch (error) {
        return { retrieveMyUser: null };
      }
    },
    { staleTime: Infinity },
  );
};

export const useInvalidateMyUser = () => {
  const queryClient = useQueryClient();
  return async () => {
    queryClient.invalidateQueries(['myUser']);
  };
};
