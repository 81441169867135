import { UnstyledButton, Group, Avatar, Text, Menu, createStyles } from '@mantine/core';
import { IconChevronRight, IconLogout, IconSettings } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

import AvatarImage from '../assets/svg/account_circle.svg';
import { useInvalidateMyUser, useMyUser } from '../hooks';
import { TokenService } from '../services';

const useStyles = createStyles((theme) => {
  return {
    user: {
      display: 'block',
      marginRight: 50,
      padding: theme.spacing.md,
      color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

      '&:hover': {
        backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
      },
    },
  };
});

export const Account = () => {
  const { data: { retrieveMyUser: user } = {} } = useMyUser();
  const invalidateMyUser = useInvalidateMyUser();

  const { classes } = useStyles();

  const navigate = useNavigate();

  const logout = () => {
    TokenService.removeToken();
    invalidateMyUser();
    navigate('/auth', { replace: true });
  };

  const goToSettings = () => {
    navigate('/settings', { replace: true });
  };

  return (
    <Menu shadow="md" width={200}>
      <Menu.Target>
        <UnstyledButton className={classes.user}>
          {user && (
            <Group>
              <Avatar src={AvatarImage} radius="xl" />
              <div>
                {/* <Text size="sm" weight={500}>
                  {user?.marketplace || 'Account not ready'}
                </Text> */}

                <Text color="dimmed" size="xs">
                  {`${user?.firstName} ${user?.lastName}`}
                </Text>
              </div>
              {<IconChevronRight size="0.9rem" stroke={1.5} />}
            </Group>
          )}
        </UnstyledButton>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item icon={<IconSettings />} onClick={goToSettings}>
          Settings
        </Menu.Item>
        <Menu.Item icon={<IconLogout />} onClick={logout}>
          Log out
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};
