import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { notifications } from '@mantine/notifications';

import { graphql } from '../../../api';
import { useClient } from '../useClient';
import { TokenService } from '../../../services';
import { useInvalidateMyUser } from '../queries';

const loginMutation = graphql(`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password)
  }
`);

export const useLogin = () => {
  const client = useClient();
  const navigate = useNavigate();
  const invalidateMyUser = useInvalidateMyUser();

  return useMutation({
    mutationFn: async (formData: any) => {
      return client.request(loginMutation, {
        email: formData.email,
        password: formData.password,
      });
    },
    onSuccess: (data) => {
      TokenService.storeToken(data.login);
      invalidateMyUser();
      navigate('/');
      notifications.show({ message: 'You have successfully logged in!' });
    },
    onError: () => {
      notifications.show({
        message: 'Authentication unsuccessful. Please try again.',
        color: 'red',
      });
    },
  });
};
