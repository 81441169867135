import { useRef } from 'react';
import { Button, Card, createStyles, Title } from '@mantine/core';
import { IconWallet } from '@tabler/icons-react';

import { MyUserForm } from '../components';
import { CreateWallet } from '../components/CreateWallet';

const useStyles = createStyles(() => ({
  header: {
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export const Settings = () => {
  const { classes } = useStyles();

  const sections = useRef([
    {
      title: 'Verifier Info',
      component: <MyUserForm />,
    },
    {
      title: 'Wallet',
      component: <CreateWallet />,
    },
  ]);

  return (
    <>
      <div className={classes.header}>
        <Title order={2}>Account Settings</Title>
      </div>
      <div>
        {sections.current.map((section, sectionIndex) => (
          <Card key={sectionIndex} withBorder mb="lg">
            <Title order={3} mb="md">
              {section.title}
            </Title>
            {section.component}
          </Card>
        ))}
      </div>
    </>
  );
};
