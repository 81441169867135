/* eslint-disable complexity */
import { Center, Group, UnstyledButton, createStyles, Text, rem, Checkbox } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { useContext } from 'react';

import { CustomTableContext } from '../CustomTableContext';
import { ActionButton } from './ActionButton';

const useStyles = createStyles((theme) => ({
  header: {
    position: 'sticky',
    top: 0,
    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
    transition: 'box-shadow 150ms ease',
    border: 'none !important',
  },

  control: {
    width: '100%',
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
  },

  icon: {
    width: rem(21),
    height: rem(21),
    borderRadius: rem(21),
  },

  header_tr: {
    borderTop: '0.0625rem solid #dee2e6',
  },

  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    height: 30,
  },
}));

export const CustomTableHeader = ({ columns, onSortChange, actions }) => {
  const { selectable, selections, setSelections, data } = useContext(CustomTableContext);

  const { classes } = useStyles();

  const isSelectionIndeterminate = selections.length > 0 && selections.length !== data.length;
  const isSelectionChecked = selections.length === data.length;

  const handleSelectionCheckbox = () => {
    if (isSelectionIndeterminate || !isSelectionChecked) {
      setSelections(Array.from(Array(data.length).keys()));
    } else if (isSelectionChecked) {
      setSelections([]);
    }
  };

  return (
    <thead className={classes.header}>
      {selectable && (
        <tr className={classes.header_tr}>
          <th colSpan={columns.length}>
            <div className={classes.actionsContainer}>
              {selectable && data.length > 0 && (
                <Group spacing="xs">
                  <Checkbox
                    onChange={handleSelectionCheckbox}
                    indeterminate={isSelectionIndeterminate}
                    checked={isSelectionChecked}
                  />
                  {selections.length > 0 && <div>{selections.length} Selected</div>}
                </Group>
              )}

              {actions?.map((action, actionIdx) => {
                if ((selectable && action.showOnSelections && selections.length > 0) || !action.showOnSelections) {
                  return (
                    <ActionButton
                      key={actionIdx}
                      action={action}
                      onClick={() => action.onClick(selections.map((selectionIdx) => data[selectionIdx]))}
                    />
                  );
                }
                return null;
              })}
            </div>
          </th>
        </tr>
      )}
      <tr className={classes.header_tr}>
        {selectable && <th style={{ width: rem(40) }}></th>}
        {columns.map((column, columnIndex) => (
          <th key={columnIndex} style={{ width: column.width || 'auto', textAlign: 'center' }}>
            <div>
              {column.sortable ? (
                <UnstyledButton className={classes.control} onClick={() => onSortChange(column)}>
                  <Group position="center">
                    <Text fw={500} fz="sm">
                      {column.name}
                    </Text>
                    <Center className={classes.icon}>
                      <IconChevronDown size="0.9rem" stroke={1.5} />
                    </Center>
                  </Group>
                </UnstyledButton>
              ) : (
                column.name
              )}
            </div>
          </th>
        ))}
      </tr>
    </thead>
  );
};
