import { CSSObject, MantineThemeOverride } from '@mantine/core';

const inputOverrideStyle: Record<string, CSSObject> = {
  input: {
    ':disabled': {
      opacity: 1,
    },
  },
};

export const theme: MantineThemeOverride = {
  colorScheme: 'light',
  // eslint-disable-next-line max-len
  fontFamily: `'Public Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`,
  headings: {
    sizes: {
      h1: {
        fontSize: '36px',
        fontWeight: '500',
      },
      h2: {
        fontSize: '30px',
        fontWeight: '500',
      },
      h3: {
        fontSize: '24px',
        fontWeight: '500',
      },
      h4: {
        fontSize: '20px',
        fontWeight: '400',
      },
      h5: {
        fontSize: '18px',
        fontWeight: '400',
      },
      h6: {
        fontSize: '16px',
        fontWeight: '400',
      },
    },
  },
  colors: {
    blue: [
      'rgb(15, 98, 152)',
      'rgb(15, 98, 152)',
      'rgb(15, 98, 152)',
      'rgb(15, 98, 152)',
      'rgb(15, 98, 152)',
      'rgb(15, 98, 152)',
      'rgb(15, 98, 152)',
      'rgb(15, 98, 152)',
      'rgb(15, 98, 152)',
      'rgb(15, 98, 152)',
    ],
  },
  black: '#000',
  white: '#fff',
  defaultRadius: 'sm',
  radius: { xs: '0.125rem', sm: '0.25rem', md: '0.5rem', lg: '1rem', xl: '2rem' },
  dir: 'ltr',
  fontSizes: { xs: '0.75rem', sm: '0.875rem', md: '1rem', lg: '1.125rem', xl: '1.25rem' },
  spacing: { xs: '0.625rem', sm: '0.75rem', md: '1rem', lg: '1.25rem', xl: '1.5rem' },
  lineHeight: 1.55,
  primaryColor: 'blue',
  primaryShade: { light: 6, dark: 8 },
  components: {
    Input: {
      defaultProps: {
        size: 'md',
      },
      styles: inputOverrideStyle,
    },
    Select: {
      defaultProps: {
        size: 'md',
      },
      styles: inputOverrideStyle,
    },
    TextInput: {
      defaultProps: {
        size: 'md',
        styles: inputOverrideStyle,
      },
    },
    PasswordInput: {
      defaultProps: {
        size: 'md',
      },
      styles: inputOverrideStyle,
    },
    DateInput: {
      defaultProps: {
        size: 'md',
        styles: inputOverrideStyle,
      },
    },
  },
  globalStyles: () => ({
    // prevent matine from adding an overflow (issue with matine DateInput not entirely visible)
    '.mantine-Modal-content': {
      overflowY: 'unset',
    },
  }),
};
