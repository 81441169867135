import { ScrollArea, Table, createStyles, LoadingOverlay } from '@mantine/core';
import { useEffect, useState } from 'react';

import { CustomTableBody, CustomTableHeader, CustomTablePagination } from './components';
import { useSorting, SortingDirection } from './hooks';
import { CustomTableContext } from './CustomTableContext';

export { SortingDirection };

const useStyles = createStyles(() => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    flex: '1 0',
  },

  table: {
    tableLayout: 'auto',
    textAlign: 'center',
    height: '100%',
    borderBottom: '0.0625rem solid #dee2e6',
    position: 'relative',
    top: 0,
  },

  scrollArea: {
    height: '100%',
    border: '0.0625rem solid #dee2e6',
    borderTop: 'none',
    borderBottom: 'none',
  },

  nothingFound: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));

interface TableColumnProps<T> {
  name: string;
  cell: (row: T) => JSX.Element;
  selector?: string;
  sortable?: boolean;
  width?: number | string;
}

interface TableRowProps {
  [key: string]: any;
}

interface TableActionProps {
  icon: JSX.Element;
  title: string;
  showOnSelections?: boolean;
  onClick: (data?: TableRowProps[], selections?: TableRowProps[]) => void;
}

type TableProps<T> = {
  dataSource: T[];
  columns: TableColumnProps<T>[];
  total: number;
  onPaginationChange?: (page?: number, size?: number) => void;
  onSortingChange?: (direction: SortingDirection, field: string) => void;
  isLoading?: boolean;
  selectable?: boolean;
  actions?: TableActionProps[];
};

export function CustomTable<T>({
  dataSource,
  columns,
  total,
  onPaginationChange,
  onSortingChange,
  isLoading,
  actions,
  selectable,
}: TableProps<T>) {
  const { classes } = useStyles();

  const onSortChange = useSorting({ onSortingChange });
  const [selections, setSelections] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);

  useEffect(() => {
    setData(dataSource);
    setSelections([]);
  }, [dataSource]);

  return (
    <CustomTableContext.Provider value={{ selections, setSelections, selectable: !!selectable, data, setData }}>
      <div className={classes.container}>
        <ScrollArea className={classes.scrollArea}>
          <LoadingOverlay visible={!!isLoading} />
          <Table className={classes.table} horizontalSpacing="lg" verticalSpacing="md" highlightOnHover>
            <CustomTableHeader columns={columns} onSortChange={onSortChange} actions={actions} />
            <CustomTableBody columns={columns} data={data} />
          </Table>
          {/* {data.length === 0 && (
            <Stack className={classes.nothingFound} align="center" spacing={0}>
              <Image src={NotFoundImage} maw={200} />
              <Text>Nothing found</Text>
            </Stack>
          )} */}
        </ScrollArea>
        <CustomTablePagination total={total} onPaginationChange={onPaginationChange} />
      </div>
    </CustomTableContext.Provider>
  );
}
