import { useEffect, useState } from "react";

export enum SortingDirection {
  ASC = 'asc',
  DESC = 'desc',
}

export const useSorting = ({ onSortingChange }) => {
  const [sorting, setSorting] = useState({ direction: SortingDirection.ASC, field: '' });


  useEffect(() => {
    if (onSortingChange) {
      onSortingChange(sorting.direction, sorting.field);
    }
  }, [sorting]);

  const onChange = (column) => {
    setSorting({
      direction: sorting.direction === SortingDirection.ASC ? SortingDirection.DESC : SortingDirection.ASC,
      field: column.selector,
    });
  };

  return onChange;
}