import { useQuery, useQueryClient } from '@tanstack/react-query';

import { graphql } from '../../../api';
import { useClient } from '../useClient';

const myPresentationsQuery = graphql(`
  query Presentations($criteria: PresentationCriteria!) {
    presentations(criteria: $criteria) {
      items {
        _id
        owner {
          _id
        }
        updatedAt
        MRN
        proof {
          code
          proof
          vp
        }
        presentationExchangeURL
        shipmentId
        orderDetails {
          currency
          price
          IOSS
          buyerLocation {
            street
            zipCode
            region
            country
          }
        }
      }
      meta {
        total
        unread
      }
    }
  }
`);

export const useMyPresentations = (pagination: { page: number; size: number }) => {
  const client = useClient();
  return useQuery(
    ['myPresentations'],
    async () =>
      client.request(myPresentationsQuery, {
        criteria: {
          pagination: {
            page: pagination.page,
            perPage: pagination.size,
          },
        },
      }),
    {
      staleTime: Infinity,
    },
  );
};

export const useInvalidateMyPresentations = () => {
  const queryClient = useQueryClient();
  return async () => {
    queryClient.invalidateQueries(['myPresentations']);
  };
};
