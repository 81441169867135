/* eslint-disable complexity */
import { Button, Group, Input, createStyles } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useEffect } from 'react';

import { useMyUser, useUpdateMyUser } from '../hooks';

const useStyles = createStyles(() => ({
  form: {
    '& > div': {
      marginBottom: 10,
    },
  },
}));

export const MyUserForm = () => {
  const { classes } = useStyles();

  const { data: { retrieveMyUser: user } = {} } = useMyUser();

  const updateMyUser = useUpdateMyUser();

  const form = useForm({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      address: {
        street: '',
        zipCode: '',
        region: '',
        country: '',
      },
    },
    validate: {
      email: (value) => !/^\S+@\S+$/.test(value),
      firstName: (value) => !value,
      lastName: (value) => !value,
      address: {
        street: (value) => !value,
        zipCode: (value) => !value,
        region: (value) => !value,
        country: (value) => !value,
      },
    },
  });

  useEffect(() => {
    if (user?._id) {
      form.setValues({
        email: user.email || '',
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        address: {
          street: user.address?.street || '',
          zipCode: user.address?.zipCode || '',
          region: user.address?.region || '',
          country: user.address?.country || '',
        },
      });
    }
  }, [user]);

  const handleSubmit = async (values) => {
    updateMyUser.mutate({
      email: values.email,
      firstName: values.firstName,
      lastName: values.lastName,
      address: {
        street: values.address?.street || '',
        zipCode: values.address?.zipCode || '',
        region: values.address?.region || '',
        country: values.address?.country || '',
      },
    });
  };

  return (
    <form className={classes.form} onSubmit={form.onSubmit(handleSubmit)}>
      <Group>
        <Input.Wrapper label="first name">
          <Input {...form.getInputProps('firstName')} disabled />
        </Input.Wrapper>

        <Input.Wrapper label="last name">
          <Input {...form.getInputProps('lastName')} disabled />
        </Input.Wrapper>
      </Group>
      <Group>
        <Input.Wrapper label="Email">
          <Input {...form.getInputProps('email')} disabled />
        </Input.Wrapper>
      </Group>

      <Group mb="sm">
        <Input.Wrapper label="street" withAsterisk>
          <Input {...form.getInputProps('address.street')} />
        </Input.Wrapper>
        <Input.Wrapper label="zip code" withAsterisk>
          <Input {...form.getInputProps('address.zipCode')} />
        </Input.Wrapper>
        <Input.Wrapper label="region" withAsterisk>
          <Input {...form.getInputProps('address.region')} />
        </Input.Wrapper>
        <Input.Wrapper label="country" withAsterisk>
          <Input {...form.getInputProps('address.country')} />
        </Input.Wrapper>
      </Group>
      <Button type="submit">Update</Button>
    </form>
  );
};
