/* eslint-disable max-statements */
import { Button, Group, Input, Title, UnstyledButton, createStyles } from '@mantine/core';
import { IconEye, IconSearch } from '@tabler/icons-react';
import { useDebouncedState } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { useEffect, useRef, useState } from 'react';

import { CustomTable, OrderStatus } from '../components';
import { RequestPresentation } from './RequestPresentation';
import { useInvalidateMyPresentations, useMyPresentations } from '../hooks';
import { VisualizePresentation } from './VisualizePresentation';

const useStyles = createStyles(() => ({
  header: {
    marginBottom: 20,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  content: {
    height: 'calc(100vh - 225px)',
  },
}));

export const VerifiablePresentations = () => {
  const [criteria, setCriteria] = useState({
    pagination: {
      page: 1,
      size: 10,
    },
  });
  const {
    isFetching,
    data: { presentations: { items: presentationList, meta: presentationMeta } } = {
      presentations: { items: [], meta: { total: 0 } },
    },
    refetch,
    remove: freeList,
  } = useMyPresentations(criteria.pagination);

  const invalidateMyPresentations = useInvalidateMyPresentations();

  const columns = useRef([
    {
      name: 'Status',
      cell: (row) => <OrderStatus status={'RELEASED' as any} />,
      sortable: false,
      width: 200,
    },
    {
      name: 'Shipment ID',
      cell: (row) => row.shipmentId,
    },
    {
      name: 'IOSS',
      cell: (row) => row.orderDetails?.IOSS,
    },
    {
      name: 'Last update',
      cell: (row) => row.updatedAt,
    },
    {
      name: 'Total amount',
      cell: (row) => `${row.orderDetails.price} ${row.orderDetails.currency}`,
    },
    {
      name: 'Buyer location',
      cell: (row) => row.orderDetails.buyerLocation?.country || 'N/A',
    },
    {
      name: 'MRN',
      cell: (row) => row.MRN,
    },
    {
      name: 'Actions',
      cell: (row) => (
        <>
          <UnstyledButton>
            <IconEye onClick={() => openProofPresentation(row)} />
          </UnstyledButton>
        </>
      ),
    },
  ]);

  const { classes } = useStyles();

  const [searchValue, setSearchValue] = useDebouncedState('', 200);

  useEffect(() => {
    return () => {
      freeList();
    };
  }, []);

  const openProofPresentation = (presentation) =>
    modals.open({
      title: 'Visualize Presentation',
      centered: true,
      size: 'lg',
      children: <VisualizePresentation presentation={presentation} />,
    });

  const openNewRequest = () =>
    modals.open({
      title: 'Request Presentation',
      centered: true,
      size: 'lg',
      children: (
        <RequestPresentation
          onClose={() => {
            // pass
            invalidateMyPresentations();
          }}
        />
      ),
    });

  const onPaginationChange = (page, size) => {
    setCriteria({
      pagination: {
        size,
        page,
      },
    });
  };

  useEffect(() => {
    refetch();
  }, [criteria]);

  return (
    <>
      <div className={classes.header}>
        <Title order={2}>Verifiable presentation list</Title>
        <Group>
          <Button size="md" onClick={openNewRequest}>
            New request
          </Button>
          <Input
            icon={<IconSearch size="1rem" stroke={1.5} />}
            onChange={(event) => setSearchValue(event.target.value)}
            placeholder="Search"
            size="md"
            disabled
          />
        </Group>
      </div>
      <div className={classes.content}>
        <CustomTable
          isLoading={isFetching}
          columns={columns.current}
          dataSource={presentationList || []}
          total={presentationMeta.total}
          onPaginationChange={onPaginationChange}
        />
      </div>
    </>
  );
};
