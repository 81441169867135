import { useMutation } from '@tanstack/react-query';

import { graphql } from '../../../api';
import { useClient } from '../useClient';

const createPresentation = graphql(`
  mutation CreatePresentation($input: PresentationCreateInput!) {
    createPresentation(input: $input) {
      shipmentId
    }
  }
`);

export const useRequestPresentation = () => {
  const client = useClient();

  return useMutation({
    mutationFn: async (formData: any) =>
      client.request(createPresentation, {
        input: {
          MRN: formData.MRN,
          shipmentId: formData.shipmentId,
          presentationExchangeURL: formData.presentationExchangeURL,
        },
      }),
  });
};
