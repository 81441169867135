import { useEffect, useState } from 'react';

export const usePagination = ({ onPaginationChange }) => {
  const [pagination, setPagination] = useState({ page: 1, size: 10 });

  const onPageChange = (page: any) => {
    if (page !== pagination.page) {
      setPagination({
        page: Number(page),
        size: pagination.size || 10,
      });
    }
  };

  const onPageSizeChange = (pageSize: any) => {
    if (pageSize !== pagination.size) {
      setPagination({
        page: pagination.page,
        size: Number(pageSize),
      });
    }
  };

  useEffect(() => {
    if (onPaginationChange) {
      onPaginationChange(pagination.page, pagination.size);
    }
  }, [pagination]);

  return { onPageChange, onPageSizeChange, pagination };
};
