import { passwordRequirements } from '.';

export const isFieldEmpty = (value) => !value;

export const isNotEmail = (value) => /^\S+@\S+$/.test(value);

export const isPasswordInvalid = (value) => !!passwordRequirements.find((req) => !req.re.test(value));

export const composeValidators = (validators: ((value) => boolean)[]) => {
  const isValidExecutor = (value) => validators.every((validatorFn) => !validatorFn(value));
  return (value) => isValidExecutor(value);
};
