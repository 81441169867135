import { useMutation } from '@tanstack/react-query';

import { graphql } from '../../../api';
import { useClient } from '../useClient';

const registerMutation = graphql(`
  mutation Register(
    $firstName: String!
    $lastName: String!
    $email: String!
    $password: String!
    $returnLink: String!
  ) {
    register(firstName: $firstName, lastName: $lastName, email: $email, password: $password, returnLink: $returnLink) {
      _id
      email
    }
  }
`);

export const useRegister = () => {
  const client = useClient();

  return useMutation({
    mutationFn: async (formData: any) => {
      return client.request(registerMutation, {
        email: formData.email,
        firstName: formData.firstName,
        lastName: formData.lastName,
        password: formData.password,
        returnLink: '',
      });
    },
  });
};
