import { Group, UnstyledButton, createStyles } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  actionButton: {
    color: 'rgb(15, 98, 152)',
    fontWeight: 500,
    marginLeft: 15,
  },
}));

export const ActionButton = ({ action, onClick }) => {
  const { classes } = useStyles();

  return (
    <UnstyledButton onClick={onClick} className={classes.actionButton}>
      <Group spacing="xs">
        {action.icon}
        {action.title}
      </Group>
    </UnstyledButton>
  );
};
