import { Paper, Tabs, createStyles } from '@mantine/core';
import { IconKey, IconUserPlus } from '@tabler/icons-react';
import { useRef } from 'react';

import { Login } from './Login';
import { Register } from './Register';
import BackgroundImage from '../../assets/images/main-background.png';

const useStyles = createStyles((theme) => ({
  container: {
    width: '100vw',
    height: '100vh',
    background: `url(${BackgroundImage}) no-repeat center center`,
    backgroundSize: 'cover',
    position: 'relative',
  },
  paper: {
    width: 440,
    background: '#fff',
    borderRadius: theme.radius.md,
    backgroundColor: theme.white,
    padding: '50px 0',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  logo: {
    marginBottom: 25,
  },
  tabs: {
    width: '100%',
  },
}));

export const Auth = () => {
  const { classes } = useStyles();

  const tabs = useRef([
    {
      label: 'Sign In',
      value: 'login',
      icon: <IconKey />,
      component: <Login />,
    },
    {
      label: 'Sign Up',
      value: 'register',
      icon: <IconUserPlus />,
      component: <Register />,
    },
  ]);

  return (
    <Tabs variant="outline" defaultValue={tabs.current[0].value} className={classes.tabs}>
      <Tabs.List position="center">
        {tabs.current.map((tab) => (
          <Tabs.Tab key={tab.value} value={tab.value} icon={tab.icon}>
            {tab.label}
          </Tabs.Tab>
        ))}
      </Tabs.List>
      {tabs.current.map((tab) => (
        <Tabs.Panel key={tab.value} value={tab.value} className={classes.tabs} p="md">
          {tab.component}
        </Tabs.Panel>
      ))}
    </Tabs>
  );
};
