import { Link, useLocation } from 'react-router-dom';
import { useRef } from 'react';
import { Navbar, createStyles, getStylesRef } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  navbar: {
    paddingTop: 50,
    backgroundColor: theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background,
  },
  links: {
    paddingTop: 50,
  },

  link: {
    ...theme.fn.focusStyles(),
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    fontSize: theme.fontSizes.sm,
    color: theme.white,
    padding: `${theme.spacing.xs} ${theme.spacing.sm}`,
    borderRadius: theme.radius.sm,
    fontWeight: 500,

    '&:hover': {
      backgroundColor: theme.fn.lighten(
        theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background!,
        0.1,
      ),
    },
  },

  linkIcon: {
    ref: getStylesRef('icon'),
    color: theme.white,
    opacity: 0.75,
    marginRight: theme.spacing.sm,
  },

  linkActive: {
    '&, &:hover': {
      backgroundColor: theme.fn.lighten(
        theme.fn.variant({ variant: 'filled', color: theme.primaryColor }).background!,
        0.15,
      ),
      [`& .${getStylesRef('icon')}`]: {
        opacity: 0.9,
      },
    },
  },
}));

export const Sidebar = () => {
  const location = useLocation();

  const { classes, cx } = useStyles();

  const routes = useRef([
    { path: 'verifiable-presentations', label: 'Verifiable presentations' },
    { path: 'help', label: 'Help' },
  ]);

  return (
    <Navbar height={'100%'} width={{ sm: 300 }} p="md" className={classes.navbar} zIndex={100}>
      <Navbar.Section grow className={classes.links}>
        {routes.current.map((route) => (
          <Link
            className={cx(classes.link, { [classes.linkActive]: route.path === location.pathname.split('/')[1] })}
            to={route.path}
            key={route.label}
          >
            {/* <item.icon /> */}
            <span>{route.label}</span>
          </Link>
        ))}
      </Navbar.Section>
    </Navbar>
  );
};
