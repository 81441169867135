import { GraphQLClient } from 'graphql-request';
import { GraphQLClientResponse } from 'graphql-request/build/esm/types';

import { TokenService } from '../../services';
import { config } from '../../config';

export const useClient = () => {
  const authToken = TokenService.getToken();
  const client = new GraphQLClient(`${config.API_URL}/en/graphql`, {
    headers: authToken
      ? {
          authorization: `Bearer ${authToken}`,
        }
      : undefined,
    responseMiddleware: (response: GraphQLClientResponse<any> | Error) => {
      if ((response as any)?.response?.status === 403) {
        TokenService.removeToken();
        window.location.href = '/';
      }
    },
  });

  return client;
};
