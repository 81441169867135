import { Navigate } from 'react-router-dom';

import { useMyUser } from '../hooks';

interface DeactivateRouteProps {
  redirectTo: string;
  children: React.ReactNode;
}
export const DeactivateRoute: React.FC<DeactivateRouteProps> = (props) => {
  const { data: { retrieveMyUser: user } = {} } = useMyUser();

  const canDeactivate = !!user;
  return !canDeactivate ? props.children : <Navigate to={props.redirectTo} replace />;
};
