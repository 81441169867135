/* eslint-disable no-console */
import { Button, Input, createStyles } from '@mantine/core';
import { useForm } from '@mantine/form';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useActivate } from '../hooks';
import { TokenService } from '../services';

const useStyles = createStyles(() => ({
  form: {
    width: '100%',
    padding: '0 1rem',
    '& > div': {
      width: '100%',
      marginBottom: 20,
    },
  },
}));

export const Activate = () => {
  const { classes } = useStyles();

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const activate = useActivate();

  const form = useForm({
    initialValues: {
      email: searchParams.get('email'),
      code: '',
    },
    validate: {},
  });

  const onSubmit = async (values) => {
    const res = await activate.mutateAsync(values);
    TokenService.storeToken(res.activate);
    navigate('/', { replace: true });
  };

  return (
    <>
      <form className={classes.form} onSubmit={form.onSubmit(onSubmit)}>
        <Input
          placeholder="email"
          name="email"
          {...form.getInputProps('email')}
          disabled={!!searchParams.get('email')}
        />
        <Input placeholder="activation code" name="code" {...form.getInputProps('code')} />
        <Button size="md" type="submit">
          Activate
        </Button>
      </form>
    </>
  );
};
