import { IconAlertCircleFilled, IconCircleCheckFilled } from '@tabler/icons-react';

enum DeclarationStatus {
  NOT_RELEASED = 'NOT_RELEASED',
  RELEASED = 'RELEASED',
  IN_PROGRESS = 'IN_PROGRESS',
}

interface OrderStatusProps {
  status: DeclarationStatus;
}

export const OrderStatus: React.FC<OrderStatusProps> = ({ status }) => {
  const statusIconMapper = new Map([
    [
      DeclarationStatus.NOT_RELEASED,
      <IconAlertCircleFilled key={0} style={{ color: 'rgb(255,108,13)', width: '25px', height: '25px' }} />,
    ],
    [
      DeclarationStatus.RELEASED,
      <IconCircleCheckFilled key={1} style={{ color: '#29AA47', width: '25px', height: '25px' }} />,
    ],
    [
      DeclarationStatus.IN_PROGRESS,
      <IconAlertCircleFilled key={2} style={{ color: 'rgb(236,204,56)', width: '25px', height: '25px' }} />,
    ],
  ]);
  return statusIconMapper.get(status);
};
