import { Button, Group, Input, Loader, ScrollArea, Stack } from '@mantine/core';
import { useForm } from '@mantine/form';
import { modals } from '@mantine/modals';
import { useRef } from 'react';

import { useRequestPresentation } from '../hooks';
import { isFieldEmpty } from '../constants/form.validators';

interface ConfirmShipmentProps {
  onClose: () => void;
}

export const RequestPresentation: React.FC<ConfirmShipmentProps> = ({ onClose }) => {
  const form = useForm({
    initialValues: {
      MRN: '',
      shipmentId: '',
      presentationExchangeURL: '',
    },
    validate: {
      MRN: isFieldEmpty,
      shipmentId: isFieldEmpty,
      presentationExchangeURL: isFieldEmpty,
    },
  });

  const requestPresentation = useRequestPresentation();

  const isSaving = useRef<boolean>(false);

  const handleSubmit = async (data) => {
    try {
      isSaving.current = true;
      // do something
      await requestPresentation.mutateAsync(data);
      isSaving.current = false;
      modals.closeAll();
      onClose();
    } catch {
      onClose();
      isSaving.current = false;
    }
  };

  return (
    <>
      <form onSubmit={form.onSubmit(handleSubmit)}>
        <ScrollArea type="auto" offsetScrollbars pl={10} pr={10}>
          <Stack mb="sm">
            <Input.Wrapper label="MRN" required>
              <Input {...form.getInputProps('MRN')} />
            </Input.Wrapper>

            <Input.Wrapper label="Shipment ID" required>
              <Input {...form.getInputProps('shipmentId')} />
            </Input.Wrapper>

            <Input.Wrapper label="Presentation URL" required>
              <Input {...form.getInputProps('presentationExchangeURL')} />
            </Input.Wrapper>
          </Stack>
        </ScrollArea>
        <Group position="center" pt={10}>
          <Button size="md" type="submit" leftIcon={isSaving.current ? <Loader color="white" variant="dots" /> : null}>
            Send Request
          </Button>
        </Group>
      </form>
    </>
  );
};
