import { Button, Code, Group, List, ScrollArea, ThemeIcon, rem } from '@mantine/core';
import { modals } from '@mantine/modals';
import { IconCircleCheck } from '@tabler/icons-react';
import { decodeToken } from 'react-jwt';

interface VisualizePresentationProps {
  presentation: any;
}

export const VisualizePresentation: React.FC<VisualizePresentationProps> = ({ presentation }) => {
  const decodedProof = JSON.stringify(decodeToken(presentation.proof.proof), null, 2);

  const handleClose = () => {
    modals.closeAll();
  };
  return (
    <>
      <List
        withPadding
        spacing="xs"
        size="md"
        center
        icon={
          <ThemeIcon color="teal" size={24} radius="xl">
            <IconCircleCheck style={{ width: rem(24), height: rem(24) }} />
          </ThemeIcon>
        }
      >
        <List.Item>Authorized Issuer verified</List.Item>
        <List.Item>IOSS verified</List.Item>
        <List.Item>Commercial invoice is conform</List.Item>
      </List>
      <ScrollArea mt="xl" type="auto" offsetScrollbars pl={10} pr={10} h={350}>
        <Code block>{decodedProof}</Code>
      </ScrollArea>
      <Group position="center">
        <Button size="sm" onClick={handleClose}>
          Close
        </Button>
      </Group>
    </>
  );
};
