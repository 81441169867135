import { Button, Card, Title } from '@mantine/core';
import { IconWallet } from '@tabler/icons-react';

import { useCreateWallet, useInvalidateMyUser, useMyUser } from '../hooks';

export interface WalletSetupProps {
  onLoadingChange?: (isLoading: boolean) => void;
  onSuccess?: () => void;
}

export const CreateWallet: React.FC<WalletSetupProps> = ({ onLoadingChange, onSuccess }) => {
  const createWallet = useCreateWallet();

  const { data: { retrieveMyUser: user } = {} } = useMyUser();
  const invalidateUser = useInvalidateMyUser();
  const handleCreateWaller = async () => {
    try {
      onLoadingChange?.(true);
      await createWallet.mutateAsync();
      invalidateUser();
      onLoadingChange?.(false);
      onSuccess?.();
    } catch {
      onLoadingChange?.(false);
    }
  };

  return (
    <Button
      leftIcon={<IconWallet fontWeight={400} />}
      size="md"
      style={{ fontWeight: 400 }}
      onClick={handleCreateWaller}
      disabled={!!user?.hasActiveWallet}
    >
      {user?.hasActiveWallet ? 'Wallet linked' : 'Create Wallet'}
    </Button>
  );
};
