/* eslint-disable no-empty-function */
/* eslint-disable no-unused-vars */
import { createContext } from 'react';

interface CustomTableContextProps {
  selectable: boolean;
  selections: number[];
  setSelections: (newSelections: number[]) => void;
  data: any[];
  setData: (newData: any[]) => void;
}

export const CustomTableContext = createContext<CustomTableContextProps>({
  selectable: false,
  selections: [],
  setSelections: (newSelections) => {},
  data: [],
  setData: (newData) => {},
});
